import React from 'react';

import { isAfter } from 'date-fns';
import { useLocation } from 'react-router-dom';

import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { EShopifyCredentialType } from '@domain/enums/common/integrations/providers/EShopify';

import { useAnnouncement } from '@helpers/hooks/pages/dashboard/admin/useAnnouncement';
import { useHome } from '@helpers/hooks/pages/dashboard/home/useHome';
import { useConfig } from '@helpers/hooks/useConfig';
import { useDate } from '@helpers/hooks/useDate';
import { useLocalStorage } from '@helpers/hooks/useLocalStorage';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';

import { getEnvironment } from '@helpers/utils/environment';
// import { getAdminStoreRoutes } from '@helpers/utils/routes/adminRoutes';
import { getUserStoreRoutes } from '@helpers/utils/routes/userRoutes';

import profitfyIcon from '@assets/img/brand/profitfy-icon.svg';
import profitfyLogo from '@assets/img/brand/profitfy-logo.svg';

import shopifyService from '@services/pages/dashboard/integrationCenter/marketplace/shopify/shopifyAuth';
import marketingService from '@services/pages/dashboard/marketing/marketing';

import Announcement from '@components/Announcement';
import DsersIssuesAnnouncement from '@components/Announcement/DsersIssuesAnnouncement';
import WorkflowAnnouncement from '@components/Announcement/WorkflowAnnouncement';
import SubscriptionAlerts from '@components/Dashboard/SubscriptionAlerts';
import FeatureModal from '@components/FeatureModal';
import BottomNavigation from '@components/common/core/Utils/BottomNavigation';
import DomainModal from '@components/common/core/Utils/DomainModal';
import NavBar from '@components/common/core/Utils/NavBar';
import PageError from '@components/common/core/Utils/PageError';
import Sidebar from '@components/common/core/Utils/Sidebar';
import MarketingIssuesAnnouncement from '@components/Announcement/MarketingIssuesAnnouncement';

// import AutomationsBeta from '@components/Announcement/AutomationsBeta';
// import InstabilityAlert from '@components/Dashboard/Alerts/Instability';
// import DemoStoreAlert from '@components/common/core/Utils/DemoStoreAlert';
// import InstabilityAlert from '@components/Dashboard/Alerts/Instability';
// import InDemoStore from '@components/Dashboard/Alerts/InDemoStore';
import Alerts from '../Alerts';
import DownloadAppMobileModal from '../DownloadAppMobileModal';
import NotificationsWrapper from '../Notifications/Wrapper';
import PageLoading from '../PageLoading';
import UpdateShopifyCredentialModal from '../UpdateShopifyCredentialModal';
// import ZendropAnnouncement from '../ZendropAnnouncement';
import ExceededFacebookProfiles from '../Alerts/ExceededFacebookProfiles';
import TransactionError from '../Alerts/TransactionError';
// import PQLModal from '../PQLModal';
// import OnboardingSetup from './OnboardingSetup';
import DeactivatedStoreModal from '../Modals/DeactivatedStore';
import DemoModal from './DemoModal';
// import PQLModal from '../PQLModal';

// import ProfitClassModal from '@components/ProfitClassModal';
// import UpgradeSuggestionModal from '@components/common/core/Utils/UpgradeSuggestionModal';

import DsersInactive from '../Alerts/DsersInactive';

import * as S from './styles';

const MAX_ANNOUNCEMENT_VIEW = 1;
const INITIAL_DATE_TO_SHOW_ANNOUNCEMENT = {
  day: 21,
  month: 12,
  year: 2022,
};
const FINAL_DATE_TO_SHOW_ANNOUNCEMENT = {
  day: 28,
  month: 12,
  year: 2022,
};

const Wrapper: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { pathname } = useLocation();
  const { handleCloseAnnouncement, isAnnouncementOpen } = useAnnouncement();
  const { handleTimezone, format, isThisWeek } = useDate();
  const { storeIntegrations } = useStoreConfig();
  const { isOnboardingFinished } = useHome();

  const {
    getUserStore,
    isInternalTeam,
    handleDownloadAppMobileModalOpen,
    isDownloadAppMobileModalOpen,
  } = useConfig();

  const isMobileWidth = useMediaQuery('768px');

  // const history = useHistory();
  const {
    isLoadingStoreConfig,
    isStoreConfigError,
    mutateStoreConfig,
    isValidatingStoreConfig,
    isDomainModalOpen,
    handleDomainModalOpen,
    mutateStore,
    store,
    accessManagementData,
    showDsersInactiveAlert,
    toggleDsersInactiveModal,
  } = useStoreConfig();

  const [storeAliasId, setStoreAliasId] = React.useState<string>('');
  const [shopifyDomain, setShopifyDomain] = React.useState<string>('');
  const [
    isUpdateShopifyCredentialModalOpen,
    setIsUpdateShopifyCredentialModalOpen,
  ] = React.useState<boolean>(false);
  const [isFeatureModalOpen, setIsFeatureModalOpen] = useLocalStorage(
    `@profitfy:${getEnvironment()}/feature-modal-config`,
    {
      is_open: false,
      closed_date: null,
      is_manually_closed: false,
      quantity_viewed: 0,
    },
  );
  const [isAppMobileContentShow, setIsAppMobileContentShow] = useLocalStorage(
    `@profitfy:${getEnvironment()}/show-app-mobile-content`,
    true,
  );
  const [isMobileDevice, setIsMobileDevice] = React.useState<boolean>(false);
  const [showLostProfitReminderModal, setShowLostProfitReminderModal] = useLocalStorage(
    `@profitfy:${getEnvironment()}/show-lost-profit-reminder-modal`,
    {
      show: false,
      lastShowingDate: null,
      manuallyClosedDate: null,
    },
  );
  const [, setShowInstabilityAlert] = useLocalStorage(
    `@profitfy:${getEnvironment()}/show-instability-high-demand-alert`,
    true,
  );

  const getRoutes = React.useCallback(() => {
    // if (isInternalTeam) return getAdminStoreRoutes();

    return getUserStoreRoutes(accessManagementData.group, accessManagementData.role);
  }, [accessManagementData]);

  const skipAppMobileContent = React.useCallback(() => {
    setIsMobileDevice(false);
    setIsAppMobileContentShow(false);
  }, [setIsAppMobileContentShow]);

  const checkIsMobileDevice = React.useCallback((): boolean => {
    const toMatch = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

    if (toMatch.test(navigator.userAgent)) {
      return true;
    }

    return false;
  }, []);

  const handleUpdateShopifyCredentialModalOpen = React.useCallback(() => {
    setIsUpdateShopifyCredentialModalOpen(currentState => !currentState);
  }, []);

  const handleFeatureModalOpen = React.useCallback(() => {
    if (isFeatureModalOpen?.is_open) {
      setIsFeatureModalOpen({
        ...isFeatureModalOpen,
        quantity_viewed: (isFeatureModalOpen?.quantity_viewed || 0) + 1,
        closed_date: new Date().toISOString(),
        is_open: false,
        is_manually_closed: false,
      });
    }
  }, [isFeatureModalOpen, setIsFeatureModalOpen]);

  const handleManualClose = React.useCallback(() => {
    setIsFeatureModalOpen({
      ...isFeatureModalOpen,
      quantity_viewed: (isFeatureModalOpen?.quantity_viewed || 0) + 1,
      closed_date: new Date().toISOString(),
      is_manually_closed: true,
      is_open: false,
    });
  }, [isFeatureModalOpen, setIsFeatureModalOpen]);

  React.useEffect(() => {
    const lastClosedDate = isFeatureModalOpen?.closed_date
      ? new Date(isFeatureModalOpen?.closed_date)
      : undefined;

    const {
      day: initialDay,
      month: initialMonth,
      year: initialYear,
    } = INITIAL_DATE_TO_SHOW_ANNOUNCEMENT;
    const { day: finalDay, month: finalMonth, year: finalYear } = FINAL_DATE_TO_SHOW_ANNOUNCEMENT;

    const isManuallyClose = isFeatureModalOpen?.is_manually_closed;
    const hasExceededQuantityViewed = isFeatureModalOpen?.quantity_viewed >= MAX_ANNOUNCEMENT_VIEW;
    const isLastClosedDateAfterInitialDateToShow = lastClosedDate
      ? isAfter(lastClosedDate, new Date(initialYear, initialMonth - 1, initialDay))
      : false;
    const isTodayAfterInitialDateToShow = isAfter(
      new Date(),
      new Date(initialYear, initialMonth - 1, initialDay),
    );
    const isTodayAfterLastDateToShow = isAfter(
      new Date(),
      new Date(finalYear, finalMonth - 1, finalDay),
    );

    if (isLastClosedDateAfterInitialDateToShow && !isManuallyClose && !hasExceededQuantityViewed) {
      setIsFeatureModalOpen({
        ...isFeatureModalOpen,
        is_open: true,
      });
    }

    if (
      !isLastClosedDateAfterInitialDateToShow &&
      isTodayAfterInitialDateToShow &&
      !isTodayAfterLastDateToShow &&
      !isFeatureModalOpen?.is_open
    ) {
      setIsFeatureModalOpen({
        is_open: true,
        closed_date: null,
        is_manually_closed: false,
        quantity_viewed: 0,
      });
    }

    if (
      isFeatureModalOpen?.is_open &&
      (!isTodayAfterInitialDateToShow || isTodayAfterLastDateToShow)
    ) {
      setIsFeatureModalOpen({
        is_open: false,
        closed_date: null,
        is_manually_closed: false,
        quantity_viewed: 0,
      });
    }
  }, [isFeatureModalOpen, setIsFeatureModalOpen]);

  const toggleInstabilityAlert = React.useCallback(() => {
    setShowInstabilityAlert((currentState: boolean) => !currentState);
  }, [setShowInstabilityAlert]);

  const toggleLostProfitReminderModal = React.useCallback(() => {
    setShowLostProfitReminderModal((prevState: any) => ({
      ...prevState,
      show: !prevState.show,
      manuallyClosedDate: !prevState.show ? null : new Date().toISOString(),
    }));
  }, [setShowLostProfitReminderModal]);

  React.useEffect(() => {
    const foundStoreAliasId = pathname.match(/store-([a-zA-Z0-9]+)/);

    if (foundStoreAliasId?.length) {
      setStoreAliasId(foundStoreAliasId[0]);
    }
  }, [pathname]);

  React.useEffect(() => {
    const userStore = getUserStore(storeAliasId);

    if (userStore) {
      handleTimezone(userStore.iana_timezone);
    }
  }, [getUserStore, handleTimezone, storeAliasId]);

  const checkShopifyCredentialType = React.useCallback(async () => {
    try {
      const { data } = await shopifyService.getShopifyCredentialPromise({ storeAliasId });

      setShopifyDomain(data?.shopify_credential.domain);

      const credentialType = data?.shopify_credential.type;

      const isPublicCredentialType = credentialType === EShopifyCredentialType.PUBLIC_APP;

      if (isPublicCredentialType) {
        setIsUpdateShopifyCredentialModalOpen(true);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  }, [storeAliasId, toast]);

  React.useEffect(() => {
    if (storeIntegrations) {
      const { store_front } = storeIntegrations;

      const foundIntegration = store_front.find(
        integration => integration.name === EDropshippingProvider.SHOPIFY,
      );

      if (foundIntegration?.is_active) {
        checkShopifyCredentialType();
      }
    }
  }, [checkShopifyCredentialType, storeIntegrations]);

  React.useEffect(() => {
    if (isAppMobileContentShow) {
      const isMobile = checkIsMobileDevice();

      if (isMobile) {
        setIsMobileDevice(true);
      }
    }
  }, [checkIsMobileDevice, isAppMobileContentShow]);

  const syncMarketingData = React.useCallback(async () => {
    if (!storeAliasId) return;

    await marketingService.syncMarketing({
      storeAliasId,
      startDate: format(new Date(), 'yyyyMMdd'),
      endDate: format(new Date(), 'yyyyMMdd'),
    });
  }, [storeAliasId, format]);

  React.useEffect(() => {
    const minutes = 15 * 60 * 1000;
    const intervalId = setInterval(syncMarketingData, minutes);

    return () => clearInterval(intervalId);
  }, [syncMarketingData]);

  React.useEffect(() => {
    if (store && !store.is_automation_onboarding_finished) {
      const wasShownThisWeek = isThisWeek(new Date(showLostProfitReminderModal.lastShowingDate));
      const wasManuallyClosedThisWeek = isThisWeek(
        new Date(showLostProfitReminderModal.manuallyClosedDate),
      );

      if (!wasShownThisWeek && !wasManuallyClosedThisWeek) {
        setShowLostProfitReminderModal((prevState: any) => ({
          ...prevState,
          show: true,
          lastShowingDate: new Date().toISOString(),
        }));
      }
    }
  }, [store, isThisWeek, showLostProfitReminderModal, setShowLostProfitReminderModal]);

  if (!pathname.includes('/dashboard/')) {
    return <>{children}</>;
  }

  if (isLoadingStoreConfig) {
    return <PageLoading />;
  }

  if (isStoreConfigError && !isValidatingStoreConfig) {
    return <PageError mutate={mutateStoreConfig} />;
  }

  const sideBarRoutes = getRoutes();

  return (
    <>
      {/* <AppMobileContent isOpen={isMobileDevice} toggle={skipAppMobileContent} /> */}

      <ExceededFacebookProfiles />

      <Alerts />

      <SubscriptionAlerts />

      <DemoModal />

      {/* {showInstabilityAlert && <InstabilityAlert toggle={toggleInstabilityAlert} />} */}

      <NotificationsWrapper />

      <UpdateShopifyCredentialModal
        domain={shopifyDomain}
        isOpen={isUpdateShopifyCredentialModalOpen}
        toggle={handleUpdateShopifyCredentialModalOpen}
      />

      {/* <ZendropAnnouncement /> */}

      {/* <AutomationsBeta /> */}
      <WorkflowAnnouncement />

      <DsersIssuesAnnouncement />

      <MarketingIssuesAnnouncement />

      <DeactivatedStoreModal />

      <FeatureModal
        isOpen={isFeatureModalOpen?.is_open}
        toggle={handleFeatureModalOpen}
        src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/feature-announcement/tiktok.png"
        url={`/${storeAliasId}/dashboard/data/integrations/tiktok`}
        handleManualClose={handleManualClose}
      />

      <Announcement isOpen={isAnnouncementOpen} onClose={handleCloseAnnouncement} />

      <DownloadAppMobileModal
        isOpen={isDownloadAppMobileModalOpen}
        toggle={handleDownloadAppMobileModalOpen}
      />

      {isMobileWidth && <BottomNavigation />}

      {!isMobileWidth && (
        <Sidebar menuRoutes={sideBarRoutes} logo={profitfyLogo} icon={profitfyIcon} />
      )}

      {/* <DemoStoreAlert /> */}

      {showDsersInactiveAlert && <DsersInactive toggle={toggleDsersInactiveModal} />}

      <NavBar />

      {!isInternalTeam && (
        <DomainModal
          isOpen={isDomainModalOpen}
          toggle={handleDomainModalOpen}
          callback={mutateStore}
          store={store}
        />
      )}

      {/* <UpgradeSuggestionModal /> */}

      {/* <ProfitClassModal
        isOpen={isModalOpen?.is_open}
        toggle={handleModalOpen}
        src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/profitclass/profitclass_08092022.png"
        url="https://hubs.li/Q01h_jHv0"
      /> */}

      <S.ContentWrapper>{children}</S.ContentWrapper>

      <TransactionError />

      {/* <PQLModal /> */}

      {/* {!isOnboardingFinished && (
        <S.OnboardingSetupWrapper>
          <OnboardingSetup />
        </S.OnboardingSetupWrapper>
      )} */}

      {/* {store?.last_30_days_lost_revenue && store.alias_id !== DEMO_STORE_PROD_ALIAS_ID && (
        <AutomationLostProfitReminderModal
          isOpen={showLostProfitReminderModal?.show}
          toggle={toggleLostProfitReminderModal}
          profit={store?.last_30_days_lost_revenue}
        />
      )} */}
    </>
  );
};

export default Wrapper;
