import styled from 'styled-components/macro';

import ImageBase from '@components/common/core/DataDisplay/Image';
import HeadingBase from '@components/common/core/DataDisplay/Heading';

export const Image = styled(ImageBase)`
  max-width: 556px;
  margin-top: 32px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
`;

export const Title = styled(HeadingBase)`
  font-size: 21px;
  color: #d9dde9;
  max-width: 477px;
  line-height: 2;
`;

export const ButtonWrapper = styled.div`
  max-width: 150px;
`;

export const Button = styled.button`
  border: 0;
  border-radius: 5px;
  background-color: #00f2a1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Mukta';
  font-weight: 700;
  font-size: 0.875rem;
  height: 42px;
  padding: 12px 22px;
  color: ${({ theme }) => theme.colors.black.default};
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
  }
`;
