import React from 'react';
import { useParams } from 'react-router-dom';

import { EUserStoreRole } from '@domain/enums/common/userStore/EUserStoreRole';
import { EBusinessType } from '@domain/enums/stores/Etype';

import { useConfig } from '@helpers/hooks/useConfig';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';

import { StoreIntegrationsProvider } from '@helpers/hooks/pages/dashboard/integrationCenter/useStoreIntegrations';

import DropshippingWrapper from '@components/Dashboard/IntegrationCenter/DropshippingWrapper';
import InfoProductWrapper from '@components/Dashboard/IntegrationCenter/InfoProductWrapper';
import EcommerceWrapper from '@components/Dashboard/IntegrationCenter/TraditionalEcommerceWrapper';
import NoAccess from '@components/common/core/Utils/NoAccess';
import NoAccessDemoStore from '@components/common/core/Utils/NoAccessDemoStore';
import { DEMO_STORE_PROD_ALIAS_ID } from '@constants/demoStore';
import { IParams } from '@domain/interfaces/IParams';

const IntegrationCenter: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const { accessManagementData } = useStoreConfig();
  const { isInternalTeam, selectedStore } = useConfig();

  const isStoreOwner = accessManagementData?.role === EUserStoreRole.OWNER;
  const hasAccessToPage =
    accessManagementData?.group?.has_integrations_full_write_access ||
    accessManagementData?.group?.has_integrations_full_read_access;

  const isDemoStore = storeAliasId && storeAliasId === DEMO_STORE_PROD_ALIAS_ID;

  if (isDemoStore) {
    return (
      <NoAccessDemoStore
        text="Você está em uma loja demo. Para realizar uma integração, clique no botão abaixo e crie sua
        loja."
      />
    );
  }

  if (!isStoreOwner && !hasAccessToPage && !isInternalTeam) {
    return <NoAccess />;
  }

  const isInfoProduct = selectedStore?.type === EBusinessType.INFO_PRODUCT;
  const isDropshipping = selectedStore?.type === EBusinessType.DROPSHIPPING;
  const isEcommerce = selectedStore?.type === EBusinessType.TRADITIONAL_ECOMMERCE;
  // const isEncapsulated = selectedStore?.type === EBusinessType.ENCAPSULATED;
  // const isDNVB = selectedStore?.type === EBusinessType.DNVB;

  return (
    <StoreIntegrationsProvider>
      {isInfoProduct && <InfoProductWrapper />}
      {isDropshipping && <DropshippingWrapper />}
      {isEcommerce && <EcommerceWrapper />}
      {/* {isEncapsulated && <EncapsulatedWrapper />}
      {isDNVB && <DNVBWrapper />} */}
    </StoreIntegrationsProvider>
  );
};

export default IntegrationCenter;
