import styled from 'styled-components/macro';
import TextBase from '@components/common/core/DataDisplay/Text';

export const Container = styled.div`
  width: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00f2a1;
`;
export const Content = styled.div`
  max-width: 1262px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-left: 90px;
  @media only screen and (max-width: 1535px) {
    max-width: 1020px;
  }
  @media only screen and (max-width: 1168px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;
export const InfoContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const Title = styled(TextBase)`
  font-weight: 700;
  font-size: 1rem;
  color: #0f1116;
  line-height: 1;
`;
export const SubTitle = styled(TextBase)`
  font-weight: 400;
  font-size: 1rem;
  color: #0f1116;
  line-height: 1;
`;
export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;
export const Button = styled.button`
  border: 0;
  border-radius: 5px;
  background-color: #2e323e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Mukta';
  font-weight: 700;
  font-size: 0.875rem;
  height: 42px;
  padding: 12px 22px;
  color: ${({ theme }) => theme.colors.white.default};
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
  }
`;
