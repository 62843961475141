import React from 'react';

import { GearSix } from 'phosphor-react';
import { useHistory } from 'react-router-dom';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const InDemoStore: React.FC = () => {
  const { user } = useConfig();
  const history = useHistory();

  const handleClick = React.useCallback(() => {
    history.push(`/onboarding/store`);
  }, [history]);

  return (
    <S.Container>
      <S.Content>
        <S.InfoContent>
          <GearSix size={40} />
          <S.TextContent>
            <S.Title>Olá, {user.first_name || 'GOAT'}!</S.Title>
            <S.SubTitle>Você está visualizando uma loja demo da Profitfy.</S.SubTitle>
          </S.TextContent>
        </S.InfoContent>
        <S.ActionsWrapper>
          <S.Button onClick={handleClick}>Criar loja</S.Button>
        </S.ActionsWrapper>
      </S.Content>
    </S.Container>
  );
};

export default InDemoStore;
