import React from 'react';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';

import { DEMO_STORE_PROD_ALIAS_ID } from '@constants/demoStore';
import * as S from './styles';

const DemoModal: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();

  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const handleIsOpen = React.useCallback(() => setIsOpen(state => !state), []);

  React.useEffect(() => {
    if (storeAliasId && storeAliasId === DEMO_STORE_PROD_ALIAS_ID) {
      setIsOpen(true);
    }
  }, [storeAliasId]);

  return (
    <S.Modal isOpen={isOpen} toggle={handleIsOpen}>
      <S.Image
        src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/demo/demo_banner.svg"
        alt="Profitfy Demo"
      />

      <S.Content>
        <S.DescriptionWrapper>
          <S.Title>Bem-vindo à loja de demonstração</S.Title>

          <S.Description>
            Explore todas as páginas e funcionalidades disponíveis. Esta é sua oportunidade de
            descobrir tudo que a Profitfy tem a oferecer. Após explorar, crie sua própria loja e
            centralize seus dados.
          </S.Description>

          {/* <S.Description>
            Após explorar, crie sua loja e centralize seus dados conosco. Estamos aqui para ajudar a
            transformar seu negócio!
          </S.Description> */}
        </S.DescriptionWrapper>

        <S.Button onClick={handleIsOpen}>Comece a explorar agora</S.Button>
      </S.Content>
    </S.Modal>
  );
};

export default DemoModal;
