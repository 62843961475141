import React from 'react';
import { useTheme } from 'styled-components';
import { X as CloseIcon } from 'phosphor-react';

import { useLocalStorage } from '@helpers/hooks/useLocalStorage';
import { useMediaQuery } from '@helpers/hooks/useMediaQuery';
import { useDate } from '@helpers/hooks/useDate';

import { getEnvironment } from '@helpers/utils/environment';

import * as S from './styles';

const MarketingIssuesAnnouncement: React.FC = () => {
  const theme = useTheme();
  const isMobileWidth = useMediaQuery('628px');
  const { isAfter } = useDate();

  const [showMarketingAnnouncement, setShowMarketingAnnouncement] = useLocalStorage(
    `@profitfy:${getEnvironment()}/marketing-issues-announcement`,
    true,
  );

  const onCloseMarketingAnnouncement = React.useCallback(() => {
    setShowMarketingAnnouncement(false);
  }, [setShowMarketingAnnouncement]);

  React.useEffect(() => {
    if (showMarketingAnnouncement) {
      const isAfterDate = isAfter(new Date(), new Date('2024-08-17T00:00:00.000Z'));

      if (isAfterDate) {
        setShowMarketingAnnouncement(false);
      }
    }
  }, [isAfter, setShowMarketingAnnouncement, showMarketingAnnouncement]);

  return (
    <>
      {showMarketingAnnouncement && !isMobileWidth && (
        <S.Wrapper>
          <S.SubTitle>Comunicado</S.SubTitle>
          <S.Title>Marketing</S.Title>
          <S.Description>
            Gostaríamos de informar que estamos enfrentando problemas temporários na parte de
            marketing da nossa plataforma.
          </S.Description>
          <S.Description>
            Estamos empenhados em efetuar as atualizações necessárias para que tudo retorne ao
            funcionamento normal.
          </S.Description>

          <S.CloseButton onClick={onCloseMarketingAnnouncement}>
            <CloseIcon size={18} color={theme.colors.gray[2]} weight="bold" />
          </S.CloseButton>
        </S.Wrapper>
      )}
    </>
  );
};

export default MarketingIssuesAnnouncement;
