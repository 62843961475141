import {
  Tiny,
  Bling,
  CartPanda,
  Shopify,
  Yampi,
  NuvemShop,
  Woocommerce,
  LojaIntegrada,
  Tray,
} from '@profitfy/pakkun-icons';

import { EEcommerceProvider } from '@domain/enums/common/integrations/EEcommerceProvider';
import { IEcommerceProviders } from '@domain/interfaces/common/integration/IEcommerceProvider';

import TinySideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/EcommerceProvider/Tiny';
import BlingSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/EcommerceProvider/Bling';
import TraySideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/EcommerceProvider/Tray';
import CartPandaSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/EcommerceProvider/CartPanda';
import YampiSideModalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/EcommerceProvider/Yampi';
import NuvemShopSidemodal from '@components/Dashboard/IntegrationCenter/SideModalContent/EcommerceProvider/NuvemShop';
import ShopifySidemodalContent from '@components/Dashboard/IntegrationCenter/SideModalContent/EcommerceProvider/Shopify';
import WoocommerceSidemodal from '@components/Dashboard/IntegrationCenter/SideModalContent/EcommerceProvider/Woocommerce';
// import LojaIntegradaSidemodal from '@components/Dashboard/IntegrationCenter/SideModalContent/EcommerceProvider/LojaIntegrada';

import OnboardingTinyContent from '@components/Onboarding/TraditionalEcommerce/Platforms/Tiny';
import OnboardingBlingContent from '@components/Onboarding/TraditionalEcommerce/Platforms/Bling';
import OnboardingTrayContent from '@components/Onboarding/TraditionalEcommerce/Platforms/Tray';
import OnboardingNuvemShopContent from '@components/Onboarding/TraditionalEcommerce/Platforms/NuvemShop';
import OnboardingYampiContent from '@components/Onboarding/TraditionalEcommerce/Platforms/Yampi';
import OnboardingShopifyContent from '@components/Onboarding/TraditionalEcommerce/Platforms/Shopify';
import OnboardingCartPandaContent from '@components/Onboarding/TraditionalEcommerce/Platforms/CartPanda';
import OnboardingWoocommerceContent from '@components/Onboarding/TraditionalEcommerce/Platforms/Woocommerce';
// import OnboardingLojaIntegradaContent from '@components/Onboarding/TraditionalEcommerce/Platforms/LojaIntegrada';

import { SHOPIFY_GATEWAYS } from '@constants/integrations/providers/shopifyGateways';
import { CART_PANDA_GATEWAYS } from '@constants/integrations/providers/cartPandaGateway';
import { NUVEM_SHOP_GATEWAYS } from '@constants/integrations/providers/nuvemShopGateway';
import { YAMPI_GATEWAYS } from '@constants/integrations/providers/yampiGateway';
import { WOO_COMMERCE_GATEWAYS } from '@constants/integrations/providers/wooCommerceGateway';

import { getNoRetroactiveGatewaySynchronizationRequestConfig } from '@helpers/utils/integrations/ecommerceProviders/noRetroactive';

import {
  getTinySynchronizationRequestConfig,
  getTinyGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/ecommerceProviders/tiny';
import {
  getBlingSynchronizationRequestConfig,
  getBlingGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/ecommerceProviders/bling';
import {
  getShopifySynchronizationRequestConfig,
  getShopifyGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/ecommerceProviders/shopify';
import {
  getNuvemShopSynchronizationRequestConfig,
  getNuvemShopGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/ecommerceProviders/nuvemShop';
import {
  getCartPandaSynchronizationRequestConfig,
  getCartPandaGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/ecommerceProviders/cartPanda';
import {
  getYampiSynchronizationRequestConfig,
  getYampiGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/ecommerceProviders/yampi';
import {
  getWoocommerceSynchronizationRequestConfig,
  getWoocommerceGatewaySynchronizationRequestConfig,
} from '@helpers/utils/integrations/ecommerceProviders/woocommerce';
import {
  getTrayGatewaySynchronizationRequestConfig,
  getTraySynchronizationRequestConfig,
} from '@helpers/utils/integrations/ecommerceProviders/tray';
// import {
//   getLojaIntegradaGatewaySynchronizationRequestConfig,
//   getLojaIntegradaSynchronizationRequestConfig,
// } from '@helpers/utils/integrations/ecommerceProviders/lojaIntegrada';

export const ECOMMERCE_PROVIDERS: Array<IEcommerceProviders> = [
  {
    id: 'tiny',
    name: 'Tiny',
    icon: Tiny,
    idToVerifyStatus: 'has_tiny_credential',
    sideModal: TinySideModalContent,
    identifier: EEcommerceProvider.TINY,
    lowerIdentifier: 'tiny',
    onboardingContent: OnboardingTinyContent,
    gateways: [],
    generateSyncRequest: getTinySynchronizationRequestConfig,
    generateGatewaySyncRequest: getTinyGatewaySynchronizationRequestConfig,
    canSyncRetroactive: true,
    isErp: true,
  },
  // {
  //   id: 'bling',
  //   name: 'Bling',
  //   icon: Bling,
  //   idToVerifyStatus: 'has_bling_credential',
  //   sideModal: BlingSideModalContent,
  //   identifier: EEcommerceProvider.BLING,
  //   lowerIdentifier: 'bling',
  //   onboardingContent: OnboardingBlingContent,
  //   gateways: [],
  //   generateSyncRequest: getBlingSynchronizationRequestConfig,
  //   generateGatewaySyncRequest: getBlingGatewaySynchronizationRequestConfig,
  //   canSyncRetroactive: true,
  //   isErp: true,
  // },
  // {
  //   id: 'tray',
  //   name: 'Tray',
  //   icon: Tray,
  //   idToVerifyStatus: 'has_tray_credential',
  //   sideModal: TraySideModalContent,
  //   identifier: EEcommerceProvider.TRAY,
  //   lowerIdentifier: 'tray',
  //   onboardingContent: OnboardingTrayContent,
  //   gateways: [],
  //   generateSyncRequest: getTraySynchronizationRequestConfig,
  //   generateGatewaySyncRequest: getTrayGatewaySynchronizationRequestConfig,
  //   canSyncRetroactive: true,
  //   isErp: false,
  // },
  {
    id: 'nuvem_shop',
    name: 'NuvemShop',
    icon: NuvemShop,
    idToVerifyStatus: 'has_nuvem_shop_credential',
    sideModal: NuvemShopSidemodal,
    identifier: EEcommerceProvider.NUVEM_SHOP,
    lowerIdentifier: 'nuvem_shop',
    onboardingContent: OnboardingNuvemShopContent,
    gateways: NUVEM_SHOP_GATEWAYS,
    generateSyncRequest: getNuvemShopSynchronizationRequestConfig,
    generateGatewaySyncRequest: getNuvemShopGatewaySynchronizationRequestConfig,
    canSyncRetroactive: true,
    isErp: false,
  },
  {
    id: 'yampi',
    name: 'Yampi Loja',
    icon: Yampi,
    idToVerifyStatus: 'has_yampi_credential',
    sideModal: YampiSideModalContent,
    identifier: EEcommerceProvider.YAMPI,
    lowerIdentifier: 'yampi',
    onboardingContent: OnboardingYampiContent,
    gateways: YAMPI_GATEWAYS,
    generateSyncRequest: getYampiSynchronizationRequestConfig,
    generateGatewaySyncRequest: getYampiGatewaySynchronizationRequestConfig,
    canSyncRetroactive: true,
    isErp: false,
  },
  {
    id: 'shopify',
    name: 'Shopify',
    icon: Shopify,
    idToVerifyStatus: 'has_shopify_credential',
    sideModal: ShopifySidemodalContent,
    identifier: EEcommerceProvider.SHOPIFY,
    lowerIdentifier: 'shopify',
    onboardingContent: OnboardingShopifyContent,
    gateways: SHOPIFY_GATEWAYS,
    generateSyncRequest: getShopifySynchronizationRequestConfig,
    generateGatewaySyncRequest: getShopifyGatewaySynchronizationRequestConfig,
    canSyncRetroactive: true,
    isErp: false,
  },
  {
    id: 'cart_panda',
    name: 'CartPanda',
    icon: CartPanda,
    idToVerifyStatus: 'has_cart_panda_credential',
    sideModal: CartPandaSideModalContent,
    identifier: EEcommerceProvider.CART_PANDA,
    lowerIdentifier: 'cart_panda',
    onboardingContent: OnboardingCartPandaContent,
    gateways: CART_PANDA_GATEWAYS,
    generateSyncRequest: getCartPandaSynchronizationRequestConfig,
    generateGatewaySyncRequest: getCartPandaGatewaySynchronizationRequestConfig,
    canSyncRetroactive: true,
    isErp: false,
  },
  {
    id: 'woocommerce',
    name: 'WooCommerce',
    icon: Woocommerce,
    idToVerifyStatus: 'has_woocommerce_credential',
    sideModal: WoocommerceSidemodal,
    identifier: EEcommerceProvider.WOO_COMMERCE,
    lowerIdentifier: 'woo_commerce',
    onboardingContent: OnboardingWoocommerceContent,
    gateways: WOO_COMMERCE_GATEWAYS,
    generateSyncRequest: getWoocommerceSynchronizationRequestConfig,
    generateGatewaySyncRequest: getWoocommerceGatewaySynchronizationRequestConfig,
    canSyncRetroactive: true,
    isErp: false,
  },
  // {
  //   id: 'loja_integrada',
  //   name: 'Loja Integrada',
  //   icon: LojaIntegrada,
  //   idToVerifyStatus: 'has_loja_integrada_credential',
  //   sideModal: LojaIntegradaSidemodal,
  //   identifier: EEcommerceProvider.LOJA_INTEGRADA,
  //   lowerIdentifier: 'loja_integrada',
  //   onboardingContent: OnboardingLojaIntegradaContent,
  //   gateways: [],
  //   generateSyncRequest: getLojaIntegradaSynchronizationRequestConfig,
  //   generateGatewaySyncRequest: getLojaIntegradaGatewaySynchronizationRequestConfig,
  //   canSyncRetroactive: true,
  //   isErp: false,
  // },
];
