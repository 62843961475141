import Wrapper from '@components/Dashboard/Home/Wrapper';
import NoAccessDemoStore from '@components/common/core/Utils/NoAccessDemoStore';
import { DEMO_STORE_PROD_ALIAS_ID } from '@constants/demoStore';
import { IParams } from '@domain/interfaces/IParams';
import { HomeProvider } from '@helpers/hooks/pages/dashboard/home/useHome';
import React from 'react';
import { useParams } from 'react-router-dom';

const Home: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();
  const isDemoStore = storeAliasId && storeAliasId === DEMO_STORE_PROD_ALIAS_ID;

  if (isDemoStore) {
    return (
      <NoAccessDemoStore
        text="Você está em uma loja demo. Clique no botão abaixo e crie sua
        loja."
      />
    );
  }

  return (
    <HomeProvider>
      <Wrapper />
    </HomeProvider>
  );
};

export default Home;
