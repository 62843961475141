import React from 'react';
import { useHistory } from 'react-router-dom';

import * as S from './styles';

interface INoAccessProps {
  text: string;
}

const NoAccessDemoStore: React.FC<INoAccessProps> = ({ text }) => {
  const history = useHistory();

  const handleClick = React.useCallback(() => {
    history.push(`/onboarding/store`);
  }, [history]);

  return (
    <S.Wrapper>
      <S.Image
        src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/dashboard/access-management/no-access-demo.svg"
        alt="Profitfy.me No Permission"
      />

      <S.Title>{text}</S.Title>

      <S.ButtonWrapper>
        <S.Button onClick={handleClick}>Crie minha loja</S.Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default NoAccessDemoStore;
